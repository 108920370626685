<template>
	<v-container fluid>

		<v-row>

			<v-col
					cols="12"
			>
				<material-card
						class="card-tabs"
						color="primary"
				>
					<template v-slot:header>
						<v-tabs
								v-model="tabs"
								background-color="transparent"
								slider-color="white"
						>
							<v-tab>All</v-tab>
							<v-tab>Love</v-tab>
							<v-tab>Idea</v-tab>
							<v-tab>Warning</v-tab>
						</v-tabs>
					</template>

					<v-tabs-items v-model="tabs">
						<v-tab-item :key="1">
							<v-data-table
								:headers="top.headers"
								:items="getTopAll"
								@click:row="(o) => goToPost(o)"
								class="row-pointer"
							>
								<template v-slot:[`item.review.type`]="{ item }">
									<svg-icon :icon="item.review.type" />
								</template>
							</v-data-table>
						</v-tab-item>
						<v-tab-item :key="2">
							<v-data-table
									:headers="top.headers"
									:items="top.love"
									@click:row="(o) => goToPost(o)"
									class="row-pointer"
							>
								<template v-slot:[`item.review.type`]>
									<svg-icon icon="love" />
								</template>
							</v-data-table>
						</v-tab-item>
						<v-tab-item :key="3">
							<v-data-table
									:headers="top.headers"
									:items="top.idea"
									@click:row="(o) => goToPost(o)"
									class="row-pointer"
							>
								<template v-slot:[`item.review.type`]>
									<svg-icon icon="idea" />
								</template>
							</v-data-table>
						</v-tab-item>
						<v-tab-item :key="4">
							<v-data-table
									:headers="top.headers"
									:items="top.warning"
									@click:row="(o) => goToPost(o)"
									class="row-pointer"
							>
								<template v-slot:[`item.review.type`]>
									<svg-icon icon="warning" />
								</template>
							</v-data-table>
						</v-tab-item>
					</v-tabs-items>
				</material-card>
			</v-col>

		</v-row>

	</v-container>
</template>

<script>
	import { globalMixIn } from '@/App'

	export default {
		data () {
			return {
				loading: {
					tab1: false,
				},
				top: {
					headers: [
						{
							sortable: false,
							text: 'Created',
							value: 'created',
						},
						{
							sortable: false,
							text: 'Author',
							value: 'author.at_name',
						},
						{
							sortable: false,
							text: 'Recipient',
							value: 'recipient.at_name',
						},
						{
							sortable: false,
							text: '',
							value: 'review.type',
							width: 40,
						},
						{
							sortable: false,
							text: 'Title',
							value: 'title'
						},
					],
					love: [],
					idea: [],
					warning: [],
				},
				tabs: 0,
			}
		},
		computed: {
			getTopAll() {
				let topAll = [...this.top.love, ...this.top.idea, ...this.top.warning]
				return topAll.sort((a, b) => new Date(b.created) - new Date(a.created))
			},
		},
		mixins: [globalMixIn],
		methods: {
			getPartnerPosts(type) {
				this.$httpInt.get(`/profiles/list/partners-posts`, { params : { type, items_per_page: 10000 } })
					.then(res => this.top[type] = res.data.result)
			},
			goToPost(item) {
				this.$router.push({name: `Post`, params: {id: item._id || item.id}})
			},
		},
		mounted() {
			this.getPartnerPosts(`love`)
			this.getPartnerPosts(`idea`)
			this.getPartnerPosts(`warning`)
		},
		name: `PartnersActivity`,
	}
</script>
